<template>
    <div>
        <div class="dropbox">
            <Dropbox @upload-photos="uploadPreflight"></Dropbox>
        </div>
        <Actions :photos="photos" :dragging="dragging" @delete-photos="removePhotos" @toggle-drag="dragging = !dragging"></Actions>
        <draggable v-model="photos" @change="updatePosition" class="photo-list" :disabled="!dragging">
            <Photo v-for="photo in loadedPhotos" :key="photo.md5" :photo="photo" :draggable="dragging"></Photo>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import Dropbox from '@/components/Dropbox'
import Actions from '@/components/Actions'
import Photo from '@/components/Photo'

export default {
    name: 'Upload',
    data() {
        return {
            photos: [],
            dragging: false
        }
    },
    computed: {
        loadedPhotos() {
            return this.photos.filter(photo => photo.visible)
        },
        checked() {
            return this.photos.filter(photo => photo.checked)
        },
        anyChecked() {
            return this.checked.length > 0
        }
    },
    components: {
        draggable,
        Dropbox,
        Actions,
        Photo
    },
    methods: {
        uploadPreflight(photos) {
            this.axios.post('admin/photos/upload/preflight/', {offset: photos.length})
            .then(() => {
                const requests = photos.map((photo, index) => {
                    return this.uploadPhoto(photo, photos.length - index - 1)
                })
                Promise.all(requests).then(() => {
                    console.log('Done')
                })
            })
            .catch(() => {})
        },
        updatePosition(e) {
            const update = {
                md5: e.moved.element.md5,
                to: e.moved.newIndex,
                from: e.moved.oldIndex
            }
            this.axios.post('admin/photos/move/', update)
            .catch(() => {})
        },
        removePhotos(photos) {
            photos.forEach(photo => {
                this.photos.splice(this.photos.indexOf(photo), 1)
            })
        },
        uploadPhoto(photo, index) {
            // Add a loading placeholder
            const placeholder = {
                loading: true,
                checked: false
            }
            this.photos.unshift(placeholder)

            // Construct and send requests (in async parallel)
            const formData = new FormData()
            formData.append(`file-${index}-idx`, index)
            formData.append(`file-${index}`, photo)

            // Make upload request and return the axios promise
            return this.axios.post('admin/photos/upload/', formData, {headers: {'content-type': 'multipart/form-data'}})
            .then(resp => {

                // When upload is complete, update the placeholder with the photo data
                Object.assign(placeholder, resp.data.uploads[0])
                if (!placeholder.uploaded) {
                    this.photos.splice(this.photos.indexOf(placeholder), 1)
                }
                placeholder.loading = false
                return placeholder.uploaded
            })
            .catch(() => {})
        },
        onScroll() {
            if (this.loadedPhotos.length != this.photos.length && (window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                for (let i = this.loadedPhotos.length; i < Math.min(this.loadedPhotos.length + 24, this.photos.length); i++) {
                    this.photos[i].visible = true
                }
            }
        }
    },
    created() {
        this.axios.get('/admin/photos/?size=10000')
        .then(resp => {
            resp.data.results.forEach((photo, index) => {
                photo.checked = false
                photo.visible = index < 40
            })
            this.photos.push(...resp.data.results)
        })
        .catch(() => {})
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll)
    }
}
</script>

<style scoped>
.dropbox {
    padding: 1rem;
}
.photo-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem;
}
</style>