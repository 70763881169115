<template>
    <form enctype="multipart/form-data" novalidate>
        <div class="dropbox-text">
            Drop your files here or click to browse
            <br>
            <FontAwesomeIcon icon="file-upload" class="icon"/>
        </div>
        <input type="file" multiple name="photos" @change="addFiles" accept="image/*" class="dropbox-input">
    </form>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'Dropbox',
    data() {
        return {
            uploads: []
        }
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        addFiles(e) {
            this.uploads.push(...e.target.files)
            this.$emit('upload-photos', this.uploads)
            this.$el.querySelector('.dropbox-input').value = ''
            this.uploads = []
        }
    }
}
</script>

<style scoped>
form {
    position: relative;
    background-color: #eee;
    border: 1px dashed lightgreen;
    height: 200px;
    border-radius: 1rem;
    transition: background-color .3s;
}
form:hover {
    background-color: #ddd;
}
.dropbox-text {
    text-align: center;
    padding: 1rem;
}
.dropbox-input {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
}
.icon {
    font-size: 2rem;
    margin-top: 1rem;
}
</style>